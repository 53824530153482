import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import AboutUs from "../components/AboutUs";
import Mission from "../components/Mission";
import Vision from "../components/Vision";
import Services from "../components/Services";
import Testimonials from "../components/Testimonials";
import ContactUs from "../components/ContactUs";
import "../styles/App.css";

// Import images
import schoolImg1 from "../images/school_img_1.jpg";
import schoolImg2 from "../images/school_img_2.jpg";
import schoolImg3 from "../images/school_img_3.jpg";

// Import AdButton component
import AdButton from "../components/AdButton";

const HomePage = () => {
  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <Navbar />
      
      {/* Hero Section with Carousel */}
      <section className="relative h-[80vh] w-full overflow-hidden">
        <Carousel
          autoPlay
          infiniteLoop
          showArrows={false}
          showThumbs={false}
          showStatus={false}
          className="h-full w-full"
        >
          {[schoolImg1, schoolImg2, schoolImg3].map((image, index) => (
            <div key={index} className="h-[80vh] w-full">
              <img
                src={image}
                alt={`Slide ${index + 1}`}
                className="object-cover h-full w-full"
              />
            </div>
          ))}
        </Carousel>

        {/* Overlay Text */}
        <div className="absolute inset-0 flex flex-col items-center justify-center bg-black bg-opacity-50 text-white text-center px-4 sm:px-10">
          <h1 className="text-3xl sm:text-5xl md:text-6xl font-bold mb-4">
            Welcome to <span className="text-yellow-400">Ogun Science Academy</span>
          </h1>
          <p className="text-lg sm:text-xl md:text-2xl mb-6">
            Empowering Students for a Bright Future
          </p>
          <button className="bg-yellow-500 text-white px-6 py-3 sm:px-8 sm:py-4 rounded-lg hover:bg-yellow-600 transition duration-300 shadow-md text-lg sm:text-xl">
            Learn More
          </button>
        </div>
      </section>
      
      {/* Main Content */}
      <main className="container mx-auto px-4 py-12">
        <section className="my-12 bg-white shadow-md rounded-lg p-6">
          <h2 className="text-2xl font-bold text-center mb-4 text-green-700">
            <span className="text-yellow-500">OGUN SCIENCE ACADEMY</span> - The Hope Of Africa!
          </h2>
          <p className="text-lg leading-relaxed text-gray-700 mb-4">
            "The tide of Africa's brain drain must be reversed by creating a world-class education and research infrastructure."
          </p>
          <AboutUs />
        </section>

        {/* Mission & Vision Sections */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 my-12">
          <section className="bg-white shadow-md rounded-lg p-6 border-t-4 border-green-700">
            <Mission />
          </section>
          <section className="bg-white shadow-md rounded-lg p-6 border-t-4 border-green-700">
            <Vision />
          </section>
        </div>

        {/* Services Section */}
        <section className="my-12 bg-white shadow-md rounded-lg p-6 border-t-4 border-yellow-500">
          <Services />
        </section>

        {/* Testimonials Section */}
        <section className="my-12 bg-white shadow-md rounded-lg p-6 border-t-4 border-green-700">
          <Testimonials />
        </section>

        {/* Contact Us Section */}
        <section className="my-12 bg-white shadow-md rounded-lg p-6 border-t-4 border-yellow-500">
          <h2 className="text-4xl font-bold text-center mb-6 text-green-700">Get in Touch</h2>
          <ContactUs />
        </section>
      </main>
      
      {/* Apply Now Button */}
      <div className="flex justify-center my-8">
        <AdButton />
      </div>
      
      <Footer />
    </div>
  );
};

export default HomePage;
