// src/components/AdmissionForm.js
import React, { useState } from "react";
import axios from "axios";
import Modal from "./Modal";

function AdmissionForm() {
  const [studentName, setStudentName] = useState("");
  const [dob, setDob] = useState("");
  const [gender, setGender] = useState("");
  const [parentName, setParentName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [presentSchool, setPresentSchool] = useState("");
  const [presentClass, setPresentClass] = useState("");
  const [soughtClass, setSoughtClass] = useState("");
  const [documents, setDocuments] = useState(null);
  const [paymentEvidence, setPaymentEvidence] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append("studentName", studentName);
    formData.append("dob", dob);
    formData.append("gender", gender);
    formData.append("parentName", parentName);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("address", address);
    formData.append("presentSchool", presentSchool);
    formData.append("presentClass", presentClass);
    formData.append("soughtClass", soughtClass);
    formData.append("documents", documents);
    formData.append("paymentEvidence", paymentEvidence);

    //      .post("http://localhost:5000/admission/", formData, {
    axios
      .post("https://ogunscienceacademy.com.ng/admission/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setSuccessMessage("Admission form submitted successfully!");
        setErrorMessage("");
        setLoading(false);
        resetForm();
        setIsModalOpen(true); // Open modal
      })
      .catch((error) => {
        setErrorMessage("Error submitting the form. Please try again.");
        setLoading(false);
        console.error(error);
      });
  };

  const resetForm = () => {
    setStudentName("");
    setDob("");
    setGender("");
    setParentName("");
    setEmail("");
    setPhone("");
    setAddress("");
    setPresentSchool("");
    setPresentClass("");
    setSoughtClass("");
    setDocuments(null);
    setPaymentEvidence(null);
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gradient-to-br from-green-400 to-blue-500">
      <div className="bg-white p-12 rounded-2xl shadow-lg w-full max-w-4xl space-y-8">
        <div className="text-center">
          <h1 className="text-5xl font-bold text-gray-800">
            Ogun Science Academy
          </h1>
          <p className="text-gray-600 mt-2">
            Complete the admission form to enroll your child.
          </p>
        </div>

        {successMessage && (
          <p className="text-green-600 text-center">{successMessage}</p>
        )}
        {errorMessage && (
          <p className="text-red-600 text-center">{errorMessage}</p>
        )}

        <form
          onSubmit={handleFormSubmit}
          className="space-y-6"
          encType="multipart/form-data"
        >
          {/* Student Info */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <label
                htmlFor="studentName"
                className="block text-gray-700 font-semibold mb-2"
              >
                Student's Full Name
              </label>
              <input
                type="text"
                id="studentName"
                value={studentName}
                onChange={(e) => setStudentName(e.target.value)}
                className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500"
                required
              />
            </div>
            <div>
              <label
                htmlFor="dob"
                className="block text-gray-700 font-semibold mb-2"
              >
                Date of Birth
              </label>
              <input
                type="date"
                id="dob"
                value={dob}
                onChange={(e) => setDob(e.target.value)}
                className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500"
                required
              />
            </div>
          </div>

          {/* Gender */}
          <div>
            <label
              htmlFor="gender"
              className="block text-gray-700 font-semibold mb-2"
            >
              Gender
            </label>
            <select
              id="gender"
              value={gender}
              onChange={(e) => setGender(e.target.value)}
              className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500"
              required
            >
              <option value="">Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>
          </div>

          {/* Parent and School Info */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <label
                htmlFor="parentName"
                className="block text-gray-700 font-semibold mb-2"
              >
                Parent's Full Name
              </label>
              <input
                type="text"
                id="parentName"
                value={parentName}
                onChange={(e) => setParentName(e.target.value)}
                className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500"
                required
              />
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-gray-700 font-semibold mb-2"
              >
                Email Address
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500"
                required
              />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <label
                htmlFor="phone"
                className="block text-gray-700 font-semibold mb-2"
              >
                Phone Number
              </label>
              <input
                type="tel"
                id="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500"
                required
              />
            </div>
            <div>
              <label
                htmlFor="address"
                className="block text-gray-700 font-semibold mb-2"
              >
                Home Address
              </label>
              <input
                type="text"
                id="address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500"
                required
              />
            </div>
          </div>

          {/* Present School and Class Info */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <label
                htmlFor="presentSchool"
                className="block text-gray-700 font-semibold mb-2"
              >
                Present School
              </label>
              <input
                type="text"
                id="presentSchool"
                value={presentSchool}
                onChange={(e) => setPresentSchool(e.target.value)}
                className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500"
                required
              />
            </div>
            <div>
              <label
                htmlFor="presentClass"
                className="block text-gray-700 font-semibold mb-2"
              >
                Present Class
              </label>
              <input
                type="text"
                id="presentClass"
                value={presentClass}
                onChange={(e) => setPresentClass(e.target.value)}
                className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500"
                required
              />
            </div>
          </div>

          {/* Sought Class */}
          <div>
            <label
              htmlFor="soughtClass"
              className="block text-gray-700 font-semibold mb-2"
            >
              Class into Which Admission is Sought
            </label>
            <input
              type="text"
              id="soughtClass"
              value={soughtClass}
              onChange={(e) => setSoughtClass(e.target.value)}
              className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500"
              required
            />
          </div>

          {/* Upload Section */}
          <div>
            <label
              htmlFor="documents"
              className="block text-gray-700 font-semibold mb-2"
            >
              Upload Supporting Documents
            </label>
            <input
              type="file"
              id="documents"
              onChange={(e) => setDocuments(e.target.files[0])}
              className="block w-full text-gray-500 file:mr-4 file:py-2 file:px-4 file:border-0 file:bg-teal-600 file:text-white hover:file:bg-teal-700 rounded-md focus:ring-2 focus:ring-teal-500"
              required
            />
          </div>

          <div>
            <label
              htmlFor="paymentEvidence"
              className="block text-gray-700 font-semibold mb-2"
            >
              Upload Payment Evidence (₦10,000)
            </label>
            <input
              type="file"
              id="paymentEvidence"
              onChange={(e) => setPaymentEvidence(e.target.files[0])}
              className="block w-full text-gray-500 file:mr-4 file:py-2 file:px-4 file:border-0 file:bg-teal-600 file:text-white hover:file:bg-teal-700 rounded-md focus:ring-2 focus:ring-teal-500"
              required
            />
          </div>

          <div>
            <button
              type="submit"
              className="w-full bg-teal-500 text-white px-6 py-3 rounded-md shadow-md hover:bg-teal-600 focus:outline-none focus:ring-4 focus:ring-teal-500"
              disabled={loading}
            >
              {loading ? "Submitting..." : "Submit Admission Form"}
            </button>
          </div>
        </form>

        {/* Modal */}
        {isModalOpen && (
          <Modal
            message="Your admission form has been submitted successfully!"
            onClose={() => setIsModalOpen(false)}
          />
        )}
      </div>
    </div>
  );
}

export default AdmissionForm;
