import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const LoginEmbed = () => {
  const openLoginPage = () => {
    window.open("https://schoolifix.com/sign2", "_blank");
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      <Navbar />

      <div className="flex flex-col items-center justify-center w-full h-96 bg-gray-100">
        <h2 className="text-2xl font-bold text-gray-700 mb-4">
          Login to School Portal
        </h2>
        <button
          onClick={openLoginPage}
          className="px-6 py-3 bg-blue-600 text-white rounded-md shadow-md hover:bg-blue-700 transition"
        >
          Open Login Page
        </button>
      </div>

      <Footer />
    </div>
  );
};

export default LoginEmbed;
