import React from "react";
import { FaRocket, FaLightbulb } from "react-icons/fa";

const Mission = () => {
  return (
    <section id="mission" className="p-6 sm:p-8 bg-gradient-to-r from-green-100 to-blue-100">
      <div className="max-w-4xl mx-auto text-center">
        <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-gray-800 mb-6">
          <span className="text-green-600">Our </span>
          <span className="text-blue-600">Mission</span>
        </h2>
        <div className="flex flex-col sm:flex-row items-center justify-center gap-6 mb-8">
          <div className="flex flex-col items-center">
            <FaRocket className="text-3xl sm:text-4xl text-green-500 mb-2" />
            <h3 className="text-lg sm:text-xl font-semibold text-gray-800 text-center">
              Promote Interest & Learning
            </h3>
          </div>
          <div className="flex flex-col items-center">
            <FaLightbulb className="text-3xl sm:text-4xl text-yellow-500 mb-2" />
            <h3 className="text-lg sm:text-xl font-semibold text-gray-800 text-center">
              Foster Creativity & Imagination
            </h3>
          </div>
        </div>
        <p className="text-gray-700 text-sm sm:text-base md:text-lg mb-4 leading-relaxed">
          Our mission at <span className="font-semibold text-blue-600">Ogun Science Academy</span> is to promote interest, learning, and creativity in science and technology through imaginative and enjoyable experiences.
        </p>
        <p className="text-gray-700 text-sm sm:text-base md:text-lg mb-4 leading-relaxed">
          We are dedicated to contributing to the nation's development of its human resources, empowering students to become responsible and innovative thinkers who positively impact society.
        </p>
      </div>
    </section>
  );
};

export default Mission;
