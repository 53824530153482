// src/components/Navbar.js
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import logo from "../../src/images/school-logo-new.jpg";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="bg-white shadow-md fixed top-0 w-full z-50 h-16">
      {" "}
      {/* Set height here */}
      <div className="container mx-auto px-4 py-2 flex justify-between items-center h-full">
        {" "}
        {/* Adjusted height */}
        {/* Logo */}
        <div className="flex items-center space-x-3">
          <Link to="/" className="flex items-center py-2 px-2">
            <img
              src={logo}
              alt="Ogun Science Academy Logo"
              className="h-8 w-8"
            />
            <span className="font-semibold text-gray-800 text-xl ml-2">
              Ogun Science Academy
            </span>
          </Link>
        </div>
        {/* Desktop Navigation */}
        <div className="hidden md:flex items-center space-x-6">
          <Link
            to="/"
            className="py-2 px-3 text-gray-700 font-semibold hover:text-green-600 transition duration-300"
          >
            Home
          </Link>
          <Link
            to="/about"
            className="py-2 px-3 text-gray-700 font-semibold hover:text-green-600 transition duration-300"
          >
            About
          </Link>
          <Link
            to="/services"
            className="py-2 px-3 text-gray-700 font-semibold hover:text-green-600 transition duration-300"
          >
            Services
          </Link>
          <Link
            to="/admissions"
            className="py-2 px-3 text-gray-700 font-semibold hover:text-green-600 transition duration-300"
          >
            GCE
          </Link>
          <Link
            to="/gallery"
            className="py-2 px-3 text-gray-700 font-semibold hover:text-green-600 transition duration-300"
          >
            Gallery
          </Link>
          <Link
            to="/contact"
            className="py-2 px-3 text-gray-700 font-semibold hover:text-green-600 transition duration-300"
          >
            Contact
          </Link>
          <Link
            to="/login"
            className="py-2 px-3 text-gray-700 font-semibold hover:text-green-600 transition duration-300"
          >
            Portal
          </Link>
          <Link
            to="/results"
            className="py-2 px-3 text-gray-700 font-semibold hover:text-green-600 transition duration-300"
          >
            Check Result
          </Link>
        </div>
        {/* Mobile Menu Button */}
        <div className="md:hidden flex items-center">
          <button className="outline-none p-2" onClick={toggleMenu}>
            {isMenuOpen ? (
              <FaTimes className="text-gray-700 text-2xl" />
            ) : (
              <FaBars className="text-gray-700 text-2xl" />
            )}
          </button>
        </div>
      </div>
      {/* Mobile Menu */}
      <div
        className={`transform ${
          isMenuOpen ? "translate-x-0" : "translate-x-full"
        } fixed top-0 right-0 w-3/4 h-full bg-white shadow-lg z-40 transition-transform duration-300 ease-in-out`}
      >
        {/* Cancel Button */}
        <div className="flex justify-end p-4">
          <button
            className="text-gray-700 text-2xl outline-none"
            onClick={toggleMenu}
          >
            <FaTimes />
          </button>
        </div>

        <ul className="flex flex-col items-center py-4">
          <li>
            <Link
              to="/"
              className="block text-lg py-4 text-gray-700 font-semibold hover:bg-green-500 hover:text-white transition duration-300"
              onClick={toggleMenu}
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              to="/about"
              className="block text-lg py-4 text-gray-700 font-semibold hover:bg-green-500 hover:text-white transition duration-300"
              onClick={toggleMenu}
            >
              About
            </Link>
          </li>
          <li>
            <Link
              to="/services"
              className="block text-lg py-4 text-gray-700 font-semibold hover:bg-green-500 hover:text-white transition duration-300"
              onClick={toggleMenu}
            >
              Services
            </Link>
          </li>
          <li>
            <Link
              to="/admissions"
              className="block text-lg py-4 text-gray-700 font-semibold hover:bg-green-500 hover:text-white transition duration-300"
              onClick={toggleMenu}
            >
              GCE
            </Link>
          </li>
          <li>
            <Link
              to="/gallery"
              className="block text-lg py-4 text-gray-700 font-semibold hover:bg-green-500 hover:text-white transition duration-300"
              onClick={toggleMenu}
            >
              Gallery
            </Link>
          </li>
          <li>
            <Link
              to="/contact"
              className="block text-lg py-4 text-gray-700 font-semibold hover:bg-green-500 hover:text-white transition duration-300"
              onClick={toggleMenu}
            >
              Contact
            </Link>
          </li>
          <li>
            <Link
              to="/login"
              className="block text-lg py-4 text-gray-700 font-semibold hover:bg-green-500 hover:text-white transition duration-300"
              onClick={toggleMenu}
            >
              Portal
            </Link>
          </li>
          <li>
            <Link
              to="/results"
              className="block text-lg py-4 text-gray-700 font-semibold hover:bg-green-500 hover:text-white transition duration-300"
              onClick={toggleMenu}
            >
              Result
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
