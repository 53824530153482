// src/components/Testimonials.js
import React from "react";
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";

const Testimonials = () => {
  return (
    <section id="testimonials" className="py-12 bg-gray-100">
      <div className="max-w-6xl mx-auto text-center">
        <h2 className="text-4xl font-bold text-gray-800 mb-8">
          <span className="text-blue-600">What Our </span>
          <span className="text-green-600">Parents Say</span>
        </h2>

        <div className="flex flex-wrap justify-center">
          {testimonialsData.map((testimonial, index) => (
            <div
              key={index}
              className="bg-white shadow-lg rounded-lg p-6 m-4 w-full md:w-1/3 transition-transform transform hover:scale-105 relative"
            >
              <FaQuoteLeft className="absolute text-3xl text-gray-300 left-0 top-4" />
              <FaQuoteRight className="absolute text-3xl text-gray-300 right-4 bottom-4" />
              <p className="text-gray-700 mb-4">"{testimonial.quote}"</p>
              <p className="font-semibold text-gray-800">
                - {testimonial.parent}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

// Testimonial data
const testimonialsData = [
  {
    quote: "The best school ever! My child has improved so much!",
    parent: "Parent 1",
  },
  {
    quote: "Amazing teachers and facilities. Highly recommended.",
    parent: "Parent 2",
  },
  {
    quote:
      "My kids love going to school now. Thank you for making education fun!",
    parent: "Parent 3",
  },
];

export default Testimonials;
