// src/components/ContactUs.js
import React from "react";
import { FaEnvelope, FaPhone, FaMapMarkerAlt } from "react-icons/fa";

const ContactUs = () => {
  return (
    <section
      id="contact"
      className="p-10 bg-gradient-to-bl from-green-200 to-blue-200"
    >
      <div className="max-w-5xl mx-auto text-center">
        <h2 className="text-4xl font-bold text-gray-800 mb-6">
          Get in Touch with{" "}
          <span className="text-blue-600">Ogun Science Academy</span>
        </h2>
        <p className="text-lg text-gray-700 mb-8">
          We’re here to help! Reach out to us for any inquiries or support.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* <div className="bg-white p-8 shadow-lg rounded-lg transition-transform transform hover:scale-105 duration-300 ease-in-out">
            <div className="flex items-center mb-4">
              <FaEnvelope className="text-4xl text-blue-500 mr-3" />
              <div>
                <h4 className="text-xl font-semibold text-gray-600">
                  Email Us
                </h4>
                <p className="text-gray-600">info@ogunscienceacademy.com.ng</p>
              </div>
            </div>
          </div> */}

          <div className="bg-white p-8 shadow-lg rounded-lg transition-transform transform hover:scale-105 duration-300 ease-in-out">
            <div className="flex items-center mb-4">
              <FaPhone className="text-4xl text-green-500 mr-3" />
              <div>
                <h4 className="text-xl font-semibold text-gray-800">Call Us</h4>
                <p className="text-gray-600">
                  <a
                    href="tel:+2348033692734"
                    className="text-blue-500 hover:underline"
                  >
                    +234 803-369-2734
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div className="bg-white p-8 shadow-lg rounded-lg transition-transform transform hover:scale-105 duration-300 ease-in-out">
            <div className="flex items-center mb-4">
              <FaMapMarkerAlt className="text-4xl text-red-500 mr-3" />
              <div>
                <h4 className="text-xl font-semibold text-gray-800">
                  Visit Us
                </h4>
                <p className="text-gray-600">Abeokuta, Ogun State</p>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-8">
          <h4 className="text-lg font-semibold text-gray-800">Follow Us</h4>
          <p className="text-gray-600">
            Stay connected with us through social media.
          </p>
          <div className="flex justify-center space-x-6 mt-4">
            {/* Social Media Icons can be added here */}
            {/* Example: <FaFacebookF size={30} /> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
